.customStepsDiv {
  width: 100% !important;
}

.customStepsDiv .ant-steps-item-tail {
  margin-top: 12px !important;
}

.customStepsDiv .ant-steps-item-content {
  margin-top: 20px !important;
}

.customStepsDiv .ant-steps-item-title {
  margin-left: 26px !important;
}

.customStepsDiv .ant-steps-item-tail {
  padding: 0 10px !important;
  margin: 13px 0 0 80px !important;
}
