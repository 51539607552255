.List {
  padding: var(--padding-1);
}

.List-section-header .Icon,
.List-item .List-item-arrow .Icon {
  color: var(--color-text-dark);
}

.List-section-header:hover .Icon {
  color: inherit;
}

.List-item .Icon {
  color: var(--color-text-light);
}

.List-section-header {
  color: var(--color-text-dark);
  border: 2px solid transparent;
  /* so that spacing matches .List-item */
}

.List-section-header--cursor {
  border-radius: 8px;
  outline: 2px solid var(--color-focus);
  outline-offset: -4px;
}

.List-section--cursor {
  background-color: color-mod(var(--color-text-light) alpha(-80%));
}

/* these crazy rules are needed to get currentColor to propagate to the right elements in the right states */
.List-section--togglable .List-section-header:hover,
.List-section--togglable .List-section-header:hover .Icon,
.List-section--togglable .List-section-header:hover .List-section-title,
.List-section--expanded .List-section-header,
.List-section--expanded .List-section-header .List-section-icon .Icon {
  color: currentColor;
}

.List-section--expanded .List-section-header .List-section-title {
  color: #4c5773;
}

.List-section-title {
  word-wrap: break-word;
}

/* LIST ITEM */
.List-item {
  display: flex;
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.List-item--cursor {
  outline: 2px solid var(--color-focus);
}

.List-item--cursor:not(.List-item--disabled),
.List-item:not(.List-item--disabled):hover,
.List-item--selected:not(.List-item--disabled) {
  background-color: currentColor;
}

/* LIST ITEM TITLE */
.List-item-title {
  color: #4c5773;
}

.List-item--disabled .List-item-title {
  color: var(--color-text-medium);
}

.List-item--cursor:not(.List-item--disabled) .List-item-title,
.List-item:not(.List-item--disabled):hover .List-item-title,
.List-item--selected:not(.List-item--disabled) .List-item-title {
  color: white;
}

/* LIST ITEM DESCRIPTION */
.List-item-description {
  margin-top: 0.25em;
  margin-bottom: 0;
  color: var(--color-text-medium);
}

.List-item--disabled .List-item-description {
  color: var(--color-text-dark);
}

.List-item--cursor:not(.List-item--disabled) .List-item-description,
.List-item:not(.List-item--disabled):hover .List-item-description,
.List-item--selected:not(.List-item--disabled) .List-item-description {
  color: rgba(255, 255, 255, 0.5);
}

/* LIST ITEM ICON */
.List-item--cursor:not(.List-item--disabled) .Icon,
.List-item:not(.List-item--disabled):hover .Icon,
.List-item--selected:not(.List-item--disabled) .Icon {
  color: white !important;
}
