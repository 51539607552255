/* NOTE: DO NOT ADD COLORS WITHOUT EXTREMELY GOOD REASON AND DESIGN REVIEW
 * NOTE: KEEP SYNCRONIZED WITH COLORS.JS
 */
:root {
  --color-brand: #509ee3;
  --color-brand-light: #ddecfa;
  --color-admin-navbar: #7172ad;
  --color-white: #ffffff;
  --color-black: #2e353b;
  --color-success: #84bb4c;
  --color-error: #ed6e6e;
  --color-warning: #f9cf48;
  --color-text-dark: #4c5773;
  --color-text-medium: #696e7b;
  --color-text-light: #949aab;
  --color-text-white: #ffffff;
  --color-bg-black: #2e353b;
  --color-bg-dark: #93a1ab;
  --color-bg-medium: #edf2f5;
  --color-bg-light: #f9fbfc;
  --color-bg-white: #ffffff;
  --color-bg-yellow: #fffcf2;
  --color-focus: #cbe2f7;
  --color-shadow: rgba(0, 0, 0, 0.13);
  --color-border: #eeecec;
  --color-accent2: #b9e68a;
}

.text-default,
:local(.text-default) {
  color: var(--color-text-dark);
}

.text-default-hover:hover {
  color: var(--color-text-dark);
}

/* brand */
.text-brand,
:local(.text-brand),
.text-brand-hover:hover,
:local(.text-brand-hover):hover {
  color: var(--color-brand);
}

.text-brand-light,
:local(.text-brand-light),
.text-brand-light-hover:hover,
:local(.text-brand-light-hover):hover {
  color: var(--color-brand-light);
}

.bg-brand-light {
  background-color: var(--color-brand-light);
}

.bg-brand-dark {
  background-color: color-mod(var(--color-brand) blackness(12%));
}

.bg-brand {
  background-color: var(--color-brand);
}

/* success */

.text-success {
  color: var(--color-success);
}
.bg-success {
  background-color: var(--color-success);
}

/* error */

.text-error,
:local(.text-error),
.text-error-hover:hover {
  color: var(--color-error);
}

.bg-error,
.bg-error-hover:hover {
  background-color: var(--color-error);
}
.bg-error-input {
  background-color: var(--color-bg-white);
}

.text-orange,
.text-orange-hover:hover {
  color: var(--color-warning);
}

.text-slate {
  color: var(--color-text-medium);
}
.text-slate-light {
  color: var(--color-text-light);
}
.text-slate-extra-light {
  background-color: var(--color-bg-light);
}

/* alt */
.bg-alt,
.bg-alt-hover:hover {
  background-color: var(--color-bg-light);
}

.text-light,
:local(.text-light),
.text-light-hover:hover {
  color: var(--color-text-light);
}

.text-medium,
:local(.text-medium),
.text-medium-hover:hover {
  color: var(--color-text-medium);
}

.text-dark,
:local(.text-dark),
.text-dark-hover:hover {
  color: var(--color-text-dark);
}

.bg-light,
.bg-light-hover:hover {
  background-color: var(--color-bg-light);
}

.bg-medium,
.bg-medium-hover:hover {
  background-color: var(--color-bg-medium);
}

.bg-dark,
.bg-dark-hover:hover {
  background-color: var(--color-bg-dark);
}

.bg-black,
.bg-black-hover:hover {
  background-color: var(--color-bg-black);
}

/* white  - move to bottom for specificity since its often used on hovers, etc */
.text-white,
:local(.text-white),
.text-white-hover:hover {
  color: var(--color-text-white);
}

@media screen and (--breakpoint-min-md) {
  .md-text-white {
    color: var(--color-text-white);
  }
}

/* common pattern, background brand, text white when hovering or selected */
.brand-hover:hover {
  color: var(--color-text-white);
  background-color: var(--color-brand);
}
.brand-hover:hover * {
  color: var(--color-text-white);
}

.bg-white,
:local(.bg-white) {
  background-color: var(--color-bg-white);
}

.bg-light-blue {
  background-color: var(--color-bg-light);
}

.bg-light-blue-hover:hover {
  background-color: var(--color-bg-medium);
}

.text-light-blue,
.text-light-blue-hover:hover {
  color: var(--color-text-light);
}
.text-slate {
  color: var(--color-text-medium);
}

.bg-transparent {
  background-color: transparent;
}

/* entity colors */

.bg-question {
  background-color: var(--color-bg-dark);
}
.text-question {
  color: var(--color-text-medium);
}

.text-light {
  color: var(--color-text-light);
}
.text-medium {
  color: var(--color-text-medium);
}
.text-dark {
  color: var(--color-text-dark);
}

.text-accent2-hover:hover {
  color: var(--color-accent2);
}
