.ant-popover-arrow {
    display: none !important;
  }

.ulOptions {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.34);
  border-radius: 5px;
}

.ulOptions li {
  width: 126px !important;
  cursor: pointer !important;
  line-height: 35px;
  padding-left: 5px;
  font-size: 14px;
}

.ulOptions li:hover{
  background: #f4f4f4 !important;
}

.x-popup-right-page-popover .ant-popover-inner-content {
  padding: 0px !important;
}

.x-popup-right-page-popover {
  padding-left: 5px !important;
}

