.x-main-box {
  padding: 20px;
}

.x-login-title {
  text-align: left;
  font-weight: bold;
  font-size: 32px;
}

.x-login-text {
  width: 430px;
  height: 56px;
  border-radius: 10px;
  font-size: 16px;
}

.x-login-text .ant-select-selector {
  height: 56px !important;
}

.x-login-text .ant-select-selection-item,.x-login-text .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}


/* .x-login-text input{background: #ECEEF3;} */
.x-login-btn {
  width: 430px;
  height: 60px;
  background: #027BFF;
  border-radius: 10px;
}

.x-login-btn span {
  font-size: 18px;
}

.x-login-btn-text {
  width: 150px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #027BFF;
  line-height: 25px;
}

.x-login-span {
  width: 46px;
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 60px;
}

.x-login-card {
  width: 523px;
  height: 666px;
  padding: 20px;
  display: inline-block;
}

.x-joinUs-card {
  width: 700px;
  height: 160px;
  margin: 0 auto;
  line-height: 110px;
}

.x-application-box {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.x-application-box-title {
  font-size: 16px;
}
/* .x-application-item .ant-card-body{background-color: gray;display: flex;justify-content: center;align-items: center;height: 142px;}
.x-application-item .ant-card-body img{width: 48px;height: 46px;}
.x-application-item  .ant-card-head {height: 52px;display: flex;justify-content: space-between;}
.x-application-item .ant-card-head-wrapper{width: 100%;}
.x-application-item  .ant-card-head{padding:0px 2px 0px 10px;}
.x-application-item  .ant-dropdown-trigger{ border: none;}
.x-main-appList  .ant-card{border-radius: 5px;}
.x-application-item .ant-card-head-title{font-size: 14px; font-weight: bold;} */
.x-main-appList  .ant-card{border-radius: 5px;}
.x-application-item .ant-card,.x-application-item .ant-card-body{ height: 182px;}
.x-application-item .ant-card-body{ position: relative; padding: 24px 18px;}
.x-application-item  .ant-dropdown-trigger{border: none;
  position: absolute;
  bottom: 25px;
  right: 12px;
  width: 24px;
  height: 24px;}
.x-application-item .x-app1{width: 28px;height: 27px;}
.x-application-item-title{
    position: absolute;
    top: 22px;
    left: 59px;
    right: 18px;
    line-height: 28px;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.x-application-item-center{
  position: absolute;
  top: 65px;
  font-size: 12px;
  color: #9B9B9B;
  line-height: 18px;
  max-height: 70px;
  word-break: break-word;
  overflow: hidden;
  padding-right: 12px;
  text-overflow: ellipsis;
}
.x-application-item-bottom-count{
  position: absolute;
  bottom: 23px;
  left: 18px;
  font-size: 14px;
  color: rgba(0, 108, 242, 1);
}
.x-application-item-bottom-action{
  width: 24px;
  opacity: 1;
}
.x-application-item .x-app-more{
  position: absolute;
  bottom: 22px;
  right: 14px;
}
.x-vcenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.x-app-header {
  line-height: 30px;
}

.x-app-header img {
  display: inline;
}

.x-join-img {
  top: 59px;
  position: absolute;
  left: 230px;
}

.zIndexMax {
  z-index: 999999 !important;
}

.x-joinUs-span {
  width: 125px;
  height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 42px;
  letter-spacing: 1px;
}

.reg-send-code {
  height: 56px !important;
  position: absolute !important;
  right: 48px;
  top: 280px;
  z-index: 9999
}

.x-a-color {
  color: #006CF2;
}

.x-fl {
  float: left;
}

.x-fr {
  float: right;
}

.x-tc {
  text-align: center;
}

.x-tr {
  text-align: right;
}

.x-w100 {
  width: 100%
}

.x-tl {
  text-align: left;
}

.x-f12 {
  font-size: 12px !important;
}

.x-f14 {
  font-size: 14px;
}

.x-f16 {
  font-size: 16px;
}

.x-f18 {
  font-size: 18px !important;
}

.x-f20 {
  font-size: 20px;
}

.x-f24 {
  font-size: 24px;
}

.x-f30 {
  font-size: 30px;
}

.x-bold {
  font-weight: bold;
}
.x-mt8 {
  margin-top: 8px;
}
.x-mt10 {
  margin-top: 10px;
}

.x-mt20 {
  margin-top: 20px;
}

.x-mt30 {
  margin-top: 30px;
}

.x-mt40 {
  margin-top: 40px;
}

.x-mr10 {
  margin-right: 10px;
}

.x-mr20 {
  margin-right: 20px;
}

.x-mb10 {
  margin-bottom: 10px;
}

.x-mb20 {
  margin-bottom: 20px;
}

.x-ml10 {
  margin-left: 10px;
}

.x-ml20 {
  margin-left: 20px;
}

.x-ml25 {
  margin-left: 25px;
}

.ant-btn-primary {
  background: var(--primary-color) !important;
}

.x-disabled {
  pointer-events: none;
  cursor: default;
}

.ant-btn{
  box-shadow:none !important;
  border-radius: 5px !important;
}
.ant-input-search-button{border-radius:0px !important}

.ant-btn:focus, .ant-btn:hover {
  color: var(--primary-color70) !important;
  border-color: var(--primary-color70) !important;
  background: #fff;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: #fff !important;
  border-color: var(--primary-color10) !important;
  background: var(--primary-color70) !important;
}

:root {
  --primary-color: #006cf2;
  --primary-color10: rgb(0, 108, 242,0.1);
  --primary-color70: rgb(0, 108, 242,0.7);
}
.x-blue{
  color:var(--primary-color) !important;
}
.ant-btn-link{
  border: none !important;
  color: var(--primary-color) !important;
}
.ant-btn-link[disabled], .ant-btn-link[disabled]:hover{
  border: none !important;
  color: rgba(0,0,0,.25) !important;
}
.ant-btn-link:hover {
  color: var(--primary-color70) !important;
}
.ant-modal-footer{
  border-top: none !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:var(--primary-color) !important;
}
.ant-tabs-tab:hover {
  color:var(--primary-color70) !important;
}
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color:var(--primary-color) !important;
}
.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  border-right-color:var(--primary-color) !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-layout-sider {
  background: white !important;
}

/* #navBarMenu{margin-top: -5px;} */
.site-page-header {
  border-bottom: 1px solid rgb(235, 237, 240);
  height: 60px !important;
  display: flex;
}

.site-page-header-main {
  border-bottom: 1px solid rgb(235, 237, 240);
  height: 60px !important;
  display: flex;
}

.ant-layout {
  background-color: #fff !important;
}

.x-border-right {
  border-right: 1px solid rgb(235, 237, 240);
}

.x-task-service .ant-form-item {
  margin: 0 !important;
}

.z-task-Detal .ant-descriptions-item-content {
  height: 200px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
}

.z-task-Detal .ant-descriptions-item-container {
  min-height: 200px;
}

#navBarMenu .ant-menu-title-content a {
  font-size: 14px;
}

#navBarMenu .ant-menu-title-content a {
  font-size: 14px;
}

.z-nav-tenantName {
  line-height: 59px;
  border-bottom: solid 1px rgb(235, 237, 240);
  font-size: 15px;
  border-right: solid 1px rgb(235, 237, 240);
  padding-left: 10px;
}

.z-nav-HomeIcon svg {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  color: var(--primary-color);
  margin-top: -10px
}

.z-nav-tenantName-span {
  margin-left: 10px;
}

.ant-page-header-heading-title {
  font-size: 16px !important;
}

.x-git-ico svg {
  width: 30px;
  height: 30px;
  margin-top: 13px;
}

.x-header-logo {
  width: 88px;
  height: 42px;
  border-radius: 5px;
  margin-right: 13px;
}

.x-header-logo1 {
  width: 70px;
  height: 42px;
  background: #F2F2F2;
  border-radius: 5px;
}

.x-header-mainProjectName {
  width: max-content;
  height: 23px;
  font-size: 16px;
  font-family: SourceHanSansSC-medium;;
  font-weight: bold;
  color: #090707;
  line-height: 23px;
}

#root img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: baseline !important;
}

.x-app-title {
  font-size: 20px;
  line-height: 70px;
  font-weight: bold
}

.x-menu-active {
  border: var(--primary-color) dashed 1px;
  color: var(--primary-color);
}

.x-menu-default {
  border: #bcadad dashed 1px;
  color: #000000;
}

.x-menu-normal {
  display: flex;
  justify-content: left;
  text-align: center;
  margin-bottom: 5px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  padding-left: 5px;
}

.x-main-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.x-main-login .ant-col-0{display: none !important;}
.x-main-login-app .ant-col-0{display: none !important;}
.x-app-delete .ant-modal-title {
  color: red;
  font-weight: bold;
}
.x-head-join-span{
  font-weight: bold;
  line-height: 40px;
  cursor: pointer;
  min-width: fit-content;
  border-top: 1px solid #D9D9D9;
  padding-left: 12px;
  color:var(--primary-color);
}
.x-head-join-span:hover{
  background-color: #D9D9D9;
}
#siderNav .ant-menu-item{color: #9A9B9B;margin: 10px;height: 36px;
  width: 236px;border-radius:5px !important;padding-left: 12px !important}
#siderNav .ant-menu-item a{color: #4B4848 !important;}
#siderNav .ant-menu-item-selected{color: #090707;}
#siderNav .ant-menu-item-selected a{color: #090707;}

#siderNav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #F2F2F2;color: #090707 !important;
}
#siderNav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
  background-color: #F2F2F2;color: #090707 !important;
}
#siderNav .ant-menu-title-content a{color:gray !important ;font-size: 15px !important; font-weight: 500;}
#siderNav .ant-menu-item:after{border-right: none;}
.ant-modal-header {border-radius: 5px 5px 0 0;}
.ant-modal-content {border-radius: 5px !important;}
.Sider-Navbar {
  display: block;
  width: 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
}

/* chenliping */
.c-main-box {
  padding: 0px;
}
.x-main-box-in{
  padding: 2px;
}
.c-edit3 .ant-select-arrow{
  margin-top: -4px;
}
.c-edit4 .ant-select-arrow{
  font-size: 14px;
  margin-top: -4px;
  color: #909191;
}
.z-UploadImage   .ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0px !important;
}
.z-widget-box-tab .ant-tabs-nav-wrap{padding-left: 15px !important;}
.z-widget-box-tab .ant-tabs-tab{padding: 7px !important;}
.z-widget-box-tab .ant-tabs-nav{margin: 0 0 5px !important;}
.x-head-notify-message{gap: 3px !important;}
#rbgcp-advanced-btn,#rbgcp-comparibles-btn,#rbgcp-color-model-btn{display: none !important;}
.z-download-define-modal-wrapClassName .ant-modal-confirm .ant-modal-confirm-btns{text-align:center !important;}
.z-download-define-modal-wrapClassName .ant-modal-confirm-body p{font-size: 16px; line-height: 35px;}
.z-download-define-modal-wrapClassName .ant-modal-confirm-body span{font-size: 16px; line-height: 35px;}
.z-download-define-modal-wrapClassName .ant-modal-confirm-btns{margin-top: 10px !important;}

 .option-row {display: flex;justify-content: space-between; /* 使选项均匀分布 */}
 .option-item {flex: 1; /* 等分每个选项的空间 */text-align: center; /* 选项文本居中 */padding: 15px;box-sizing: border-box; /* 包含内边距和边框 */}
.z-icon-select-div .ant-select-selection-search{padding-left:25px;}
.z-icon-select-div .ant-select-selection-item{padding-left:25px !important;}
