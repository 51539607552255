@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~design-system-old/build/css/design-system-old.css";
@import "~design-system/build/css/design-system.css";
@import "theme/colors.css";
@import "theme/defaultTheme.css";
@import "theme/wds.css";
@import "assets/fonts/custom/index.css";

:root {
  /* TODO: This needs to be fixed! This oveerride is to mainatain branding changes. */
  --ads-color-background-secondary: var(--ads-v2-color-bg-subtle);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  color: var(--ads-v2-color-fg);
}

body.dragging * {
  cursor: grabbing !important;
}

/**
  Disable outline completely from all components
*/
:focus,
:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

/**
  Disable outline for adsv2 select component
*/
.ads-v2-select.rc-select-focused {
  outline: none !important;
  outline-offset: 0 !important;
}

body.overlay {
  opacity: 0;
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: flex;
  width: 100%;
}

span.bp3-popover-target > * {
  flex-grow: 1;
}

.bp3-popover,
.bp3-popover2 {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.bp3-popover2-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.action-selector-popup {
  background-color: var(--ads-v2-color-bg);
  border-radius: var(--ads-v2-border-radius) !important;
  border: 1px solid var(--ads-v2-color-border-muted) !important;
}

.action-selector-popup span.bp3-popover-target > * {
  max-width: 100%;
}

add-action.disabled span {
  cursor: not-allowed;
}

.action-selector-popup .bp3-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

/**
  action selector block styles
**/
.bp3-submenu.bp3-popover > .bp3-popover-content {
  box-shadow: none;
}

.action-card-border {
  border-color: var(--ads-v2-color-border);
}

.action-card-next-selected {
  border-bottom: 1px solid var(--ads-v2-color-border-emphasis-plus);
}

.action-card-border-selected {
  border-color: var(--ads-v2-color-border-emphasis-plus) !important;
}

.action-card-last-block {
  border-radius: 0px 0px var(--ads-v2-border-radius) var(--ads-v2-border-radius);
}

.main-block-radius {
  border-radius: var(--ads-v2-border-radius);
}

.main-block-radius-selected {
  border-radius: var(--ads-v2-border-radius) var(--ads-v2-border-radius) 0px 0px;
}

.bp3-popover.bp3-minimal.bp3-submenu {
  padding-right: 0px;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}

.display-none {
  display: none;
}

.t--editor-appname-menu-portal {
  z-index: 9 !important;
}

.bp3-popover .bp3-input {
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--ads-v2-color-border);
  border-radius: var(--ads-v2-border-radius) !important;
}

.bp3-popover .bp3-input:focus {
  border-color: var(--ads-v2-color-border-emphasis);
  outline: 0;
}

.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.bp3-minimal.action-selector-dropdown {
  margin-top: 0px !important;
}

.layout-control.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.none-shadow-popover {
  box-shadow: none;
  border: 0px;
}

.bp3-datepicker {
  border-radius: 0 !important;
}

/* making both the Modal layer and the Dropdown layer */
.bp3-modal-widget,
.appsmith_widget_0 > .bp3-portal {
  z-index: 2 !important;
}

/* Portals on the Modal widget */
.bp3-modal-widget .bp3-portal {
  z-index: 21 !important;
}

.bp3-popover2-content {
  background-color: var(--ads-v2-color-bg) !important;
  border-radius: var(--ads-v2-border-radius) !important;
}

.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.7) !important;
}

.bp3-overlay-zindex {
  z-index: 20 !important;
}

/** color picker input control */
.color-picker-input,
.color-picker-input > .bp3-popover-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.color-picker-input {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.icon-select-popover,
.icon-select-popover > .bp3-popover-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.icon-select-popover {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.error-menuitem {
  color: var(--ads-v2-color-fg-error) !important;
}

.error-menuitem > .ads-v2-menu__menu-item-children {
  color: var(--ads-v2-color-fg-error) !important;
}

.error-menuitem:hover {
  background-color: var(--ads-v2-color-red-50) !important;
}

.error-menuitem:active {
  background-color: var(--ads-v2-color-red-100) !important;
}

.menuitem-nohover:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.react-datepicker__children-container {
  margin-top: -10px !important;
}

.reconnect-datasource-modal {
  z-index: 9 !important;
}

.datePicker2Control .react-datepicker-wrapper {
  width: 100%;
}

.carouselWidgetCarousel .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 72px !important;
  height: 72px !important;
  vertical-align: top;
}

.carouselWidgetCarousel .ant-upload-select-picture-card {
  width: 72px !important;
  height: 72px !important;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
}

.carouselWidgetCarousel .ant-upload-list-picture-card .ant-upload-list-item,
.carouselWidgetCarousel .ant-upload-list-picture .ant-upload-list-item {
  position: relative;
  height: 72px;
  padding: 7px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.Widget_dynamicForm_ .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  font-size: inherit;
  color: inherit;
}

.datePicker2Control__popover .ant-btn-primary[disabled],
.datePicker2Control__popover .ant-btn-primary[disabled]:hover,
.datePicker2Control__popover .ant-btn-primary[disabled]:focus,
.datePicker2Control__popover .ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
