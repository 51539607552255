.at-input{
  margin-left: 0;
  padding: 0;
}
.at-button,.at-input,.at-list{
  height: 100%;
  overflow: hidden;
}
.at-button{
  line-height: inherit;
  align-items: center;
}

.at-button, .at-grid__flex .content-inner__text, .at-input__title, .at-input__input, .at-input input,
.at-list__item, .at-list__item .item-extra__info, .at-textarea, .at-textarea__textarea,
.at-radio__title, .at-radio__icon,.at-checkbox__title
{ font-size: 16px; }

.at-button--small, .at-input-number, .at-input-number__input, .at-input-number input,
.at-list__item .item-content__info-note, .at-switch__title, .at-textarea__counter
{ font-size: 14px; }

.at-input-number{border-radius: 4px;}
.at-input-number__btn{padding: 6px;}
.at-input-number__btn-subtract, .at-input-number__btn-add{
  font-size: 15px;line-height: 16px;
}
.at-input-number__input, .at-input-number input{
  width: 40px;
}

.at-grid__flex .content-inner__img{width:40px;height:40px;}

.at-input__title{width: 86px;}
.at-input__input{margin-right: 0;padding-right: 0; height: inherit;}
.at-input input{height: 100%;}

.at-list__item{padding: 12px;}
.at-list__item-content{margin-right: 20px;}
.at-list__item .item-extra__icon-arrow{font-size: 24px;}
.at-list__item::after{left: 12px;}
.at-list__item .item-extra__info{padding-right: 15px;}

.at-switch{margin-left: 0; padding: 12px 16px 12px 0;background: transparent;}
.at-switch__container{background: transparent;}
.at-switch::after{border: none;}
/*taro-switch-core{width:26px;height: 16px;}
.weui-switch:before, .weui-switch-cp__box:before,
.weui-switch:after, .weui-switch-cp__box:after{width:15px;height:15px;}
.weui-switch:checked:after, .weui-switch-cp__input:checked~.weui-switch-cp__box:after{
  -webkit-transform: translate(10px);
  transform: translate(10px);
}*/

.at-textarea{height: 100%;padding: 0;border-radius:4px;}
.at-textarea__counter{padding-top: 6px;}

.at-radio__option{padding-left:16px;}
.at-radio__option-wrap, .at-checkbox__option-wrap{padding: 12px 16px 12px 0;}

.at-checkbox__option{padding-left: 16px;}
.at-checkbox__icon-cnt{margin-top: 2px; margin-right: 12px; width: 20px;min-width: 20px;height: 20px; font-size: 12px;}

.at-loading__ring, .at-loading{width:18px;height:18px;}
.at-button__icon{margin-left:10px;margin-right:10px;}

.at-divider{height:1px;}

.at-list__item--thumb .item-thumb{width:28px;height:28px;}

.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions-btn:hover{border:none!important;background:none!important;}
.at-input__container,
.at-progress{height: inherit;}
