.rc-virtual-list-holder-inner .rc-select-item-option-state{
    position: absolute !important;
    left: 16px !important;
    width: 18px !important;
    top: 9px !important;
  }
  
  .rc-virtual-list-holder-inner .rc-select-item-option-content{
    position: absolute !important;
    left: 42px !important;
  }
    
  .rc-virtual-list-holder-inner .rc-select-item-option{
    position: relative !important;
  }