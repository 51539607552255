@tailwind base;
@tailwind components;
@tailwind utilities;

svg{
    vertical-align: baseline;
}
.x-app-title{font-size: 20px; line-height: 70px; font-weight:bold}
#z-widget-sidebar .ant-collapse-content-box{padding: 0 !important;margin-left: 20px;margin-top: 10px ;}
#z-widget-sidebar .jhagkT{background-color:#F7F7F7 !important;}
/* .gGEdna::before {margin-bottom: 2px !important;}
.gGEdna:checked::before {margin-top: 2px !important;}
.bp3-radio-text{margin-top: 10px !important;}*/
.ant-image-preview-img{display:inline!important;}
.x-ImportDataComponent .ant-upload-picture-card-wrapper{margin-top: 20px !important;}
.x-ImportDataComponent .ant-upload{width: 100% !important;height: 221px !important; }
.x-ImportDataComponent .file-notice{margin-top: 20px;font-size: 14px;color: #7D7D7D;}
.x-ImportDataComponent .step-content .x-step2{ padding: 50px 0 0 20px;
    text-align: center;}
.x-ImportDataComponent .step-content .x-success{ margin-top: 20px;
    font-size: 20px;
    font-weight: bold;}
.x-ImportDataComponent .step-content .x-notice{     color: #7D7D7D;
    font-size: 14px;
    margin-top: 12px;}
.x-ImportDataComponent  .x-close{    width: 128px;
    height: 50px;
    font-size: 16px;
    margin-top: 48px;}
.x-ImportDataComponent    .ant-upload-text{ margin-top: 27px; font-size: 18px; color:#7D7D7D;}
.x-ImportDataComponent    .ant-upload-hint{ margin-top: 5px; font-size: 14px; color:#7D7D7D;}
.x-ImportDataComponent    .ant-modal-confirm-btns{display: none;}


