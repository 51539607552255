.full-height,
:local(.full-height) {
  height: 100%;
}

:root {
  --padding-1: 0.5rem;
  --padding-2: 1rem;
  --padding-3: 1.5rem;
  --padding-4: 2rem;

  --margin-1: 0.5rem;
  --margin-2: 1rem;
  --margin-3: 1.5rem;
  --margin-4: 2rem;
}

.mt2 {
  margin-top: var(--padding-2);
}

.PopoverBodyBackground {
  border: 1px solid #edf2f5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  border-radius: 6px;
  overflow: auto;
}

.listItemSelect {
  background-color: #509ee3;
  color: white;
}
