.wh100per {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.mxLabelLayout {
  width: 98.6%;;
  height: 100%;
  background: linear-gradient(180deg, #FAFCFF 0%, #F2F8FF 100%);
  border-radius: 15px;
  margin: 1px 0px 1px 2px;
}

.mxLabelHeader {
  width: 100%;
  height: 36px;
  color: rgba(9, 7, 7, 1);
  line-height: 14px;
  font-size: 14px;
  padding-top: 11px;
  border-bottom: 1px dashed #ADBCD1;
}

.mxLabelContent li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  padding: 5px;
  color: #101010;
  font-weight: bold;
}

.mxLabelContent li:hover {
  color: #006CF2;
}

.mxLabelContent li span.lf {
  float: left;
  text-align: left;
}

.mxLabelContent li span.rf {
  float: right;
  text-align: right;
}

.mxLabelFooter {
  width: 100%;
  height: 30px;
  text-align: center;
  border-radius: 9px;
}

.mxLabelFooter label {
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  color: #006CF2;
  margin-top: 12px;
}

.createModel .ant-form-item {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0px;
  vertical-align: top;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.createModel .ant-table.ant-table-small .ant-table-footer,
.createModel .ant-table.ant-table-small .ant-table-tbody > tr > td,
.createModel .ant-table.ant-table-small .ant-table-thead > tr > th,
.createModel .ant-table.ant-table-small .ant-table-title,
.createModel .ant-table.ant-table-small tfoot > tr > td,
.createModel .ant-table.ant-table-small tfoot > tr > th {
  padding: 2px !important;
}

.createModel .ant-table-tbody > tr > td,
.createModel .ant-table-thead > tr > th,
.createModel .ant-table tfoot > tr > td,
.createModel .ant-table tfoot > tr > th {
  position: relative;
  padding: 2px;
  overflow-wrap: break-word;
}

.createModel .ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.modelRelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 23px;
}

.modelRelLeft {
  width: 227px;
  height: 72px;
  line-height: 72px;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(250, 252, 255, 1) 0%, rgba(242, 248, 255, 1) 100%);
  text-align: center;
  border: 1px solid rgba(173, 188, 209, 1);
  color: rgba(9, 7, 7, 1);
  font-size: 14px;
}

.modelRelRight {
  width: 227px;
  height: 72px;
  line-height: 72px;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(250, 252, 255, 1) 0%, rgba(242, 248, 255, 1) 100%);
  text-align: center;
  border: 1px solid rgba(173, 188, 209, 1);
  color: rgba(9, 7, 7, 1);
  font-size: 14px;
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 5px 0;
}

.ddl-create-list-container {
  overflow-y: auto;
  height: 350px;
  margin: 5px;
}

.ddl-create-list-container .ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  color: rgba(0, 0, 0, .85);
}

.publish .ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0;
  color: rgba(0, 0, 0, .85);
}


.modelHeader {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(9, 7, 7, 1);
  line-height: 14px;
  font-size: 14px;
  border-bottom: 1px dashed #ADBCD1;
}

.modelHeader .modelId {
  display: none;
}

.modelProperty {
  color: #101010;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modelProperty .lf {
  margin-left: 10px;
}

.modelProperty .rf {
  margin-right: 10px;
}
