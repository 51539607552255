.CodeMirror {
  background-color: transparent;
  height: 36px;
  width: 100%;
}
.CodeMirror-hints{
  z-index: 1000!important;
}
.CodeMirror-vscrollbar{
  opacity: 0;
}
