.mxRubberband {
  background-color: rgb(58, 58, 207);
  position: absolute;
}

.mxPopupMenu {
  position: absolute;
  width: 80px;
}

.mxPopupMenu table {
  box-shadow: 0 0 10px rgba(0, 0, 0, .6);
  border-radius: 5px;
  cursor: pointer;
}

#proSettingsPanel .ant-switch-checked {
  background-color: #1890ff !important;
}

#proSettingsPanel .ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, .25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#proSettingsPanel .ant-switch-small {
  min-width: 28px !important;
  height: 16px !important;
  line-height: 16px !important;
}


#attributeBoxDiv > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0px 0px;
  color: rgba(0, 0, 0, .85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all .3s, visibility 0s;
}

#attributeBoxDiv .ant-collapse-content > .ant-collapse-content-box {
  padding: 4px;
}

#attributeBoxDiv .ant-form-item {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0 0 12px;
  vertical-align: top;
}

.listLiActive {
  padding-left: 1px;
  background-color: #daeffa;
}

.cell_selected {
  border: 2px green solid;
}
