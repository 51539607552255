@import "./tailwind.css";

/**
* ---------------------------------------------------------------------------------------------------
* general
* ---------------------------------------------------------------------------------------------------
*/
body,
html {
  @apply w-full h-full overflow-x-hidden;
}

/**
* ---------------------------------------------------------------------------------------------------
* blueprint specific css overrides
* ---------------------------------------------------------------------------------------------------
*/
.bp3-panel-stack-view {
  background: none;
}

/**
* ---------------------------------------------------------------------------------------------------
* custom css
* ---------------------------------------------------------------------------------------------------
*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--ads-v2-color-bg-emphasis) transparent;
}

/* For some reason, margin-left: -160px; is being added to the react-toastify stylesheet on the .Toastify__toast-container--top-center
and .Toastify__toast-container--bottom-center classes, which messes with the placement of toast. This line resets that. */
.Toastify__toast-container--top-center,
.Toastify__toast-container--bottom-center {
  margin-left: 0;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--ads-v2-color-bg-emphasis);
  border-radius: 36px;
  opacity: 0.5;
}

.diagnol-cross {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='red' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

input::placeholder,
textarea::placeholder {
  color: var(--ads-v2-color-fg-subtle);
}

.z-smith-label:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}

/* chenliping */
@media (min-width: 768px){
  .c-edit1 .ant-col-md-6{
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 0px !important;
  }
}
@media (min-width: 992px){
 .c-edit2 .ant-col-lg-4 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 0px !important;
  }
}
